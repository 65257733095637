@media only screen and (min-width: 768px) {
    .content__padding {
        padding: 10px 120px 3px;
    }
  }

@media only screen and (max-width: 767px) {
.content__padding {
    padding: 10px 20px 3px;
}
}
